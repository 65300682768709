import React, { useState } from "react";
import "./Logs.css";
import { FaDownload } from "react-icons/fa";
import { IoMdRemoveCircle } from "react-icons/io";
import { AiOutlineClear } from "react-icons/ai";
import ConfirmationModal from "../components/ConfirmationModal";

function Logs() {
  const [logs, setLogs] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState({
    msg: "",
    usr: "",
  });

  const clearMsg = "Clear all logs";
  const deleteMsg = "Delete the log";

  const clearAll = () => {
    console.log("All logs cleared");
  };

  const deleteLog = (index) => {
    showConfirmation(true);
    setConfirmationMessage({ msg: deleteMsg, usr: "" });
  };

  return (
    <div>
      {showConfirmation && (
        <ConfirmationModal
          confirmationMessage={confirmationMessage}
          setShowConfirmation={setShowConfirmation}
          clearAll={clearAll}
        />
      )}
      <div className="tableDiv">
        <table className="log_table">
          <thead>
            <tr>
              <th style={{ width: "4%" }}></th>
              <th style={{ display: "flex", justifyContent: "space-between" }}>
                Logs
                <div
                  className="userOperation"
                  onClick={() => {
                    setShowConfirmation(true);
                    setConfirmationMessage({ msg: clearMsg, user: "" });
                  }}
                >
                  <AiOutlineClear className="icon" />
                  Clear All
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {logs.map((item, index) => (
              <tr key={index}>
                <td>{index}</td>
                <td
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {item.name}
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div className="userOperation">
                      <FaDownload className="icon" /> Download
                    </div>
                    <div
                      className="userOperation"
                      onClick={() => deleteLog(index)}
                    >
                      <IoMdRemoveCircle
                        className="icon"
                        style={{ color: "red" }}
                      />{" "}
                      Delete
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Logs;
