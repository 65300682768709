// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
}

.main {
  width: 100%;
}

.sidebar {
  background-color: #dadada;
  color: #000000;
  height: 100vh;
  width: 200px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  font-weight: bold !important;
}

.top_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.logo {
  font-size: 30px;
  color: #0a6e2b;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  color: #000000;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link.bottom {
  margin-bottom: 10px;
  cursor: pointer;
}

.link:hover {
  background-color: #0a6e2b;
  transition: all 0.5s;
}

.active {
  background-color: #0a6e2b;
}

.icon,
.link-text {
  font-size: 20px;
}

/* div {
  font-weight: bold;
} */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,YAAY;EACZ,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,eAAe;AACjB;;AAEA;;GAEG","sourcesContent":["* {\r\n  margin: 0;\r\n  padding: 0;\r\n  text-decoration: none;\r\n}\r\n\r\n.container {\r\n  display: flex;\r\n}\r\n\r\n.main {\r\n  width: 100%;\r\n}\r\n\r\n.sidebar {\r\n  background-color: #dadada;\r\n  color: #000000;\r\n  height: 100vh;\r\n  width: 200px;\r\n  transition: all 0.2s;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  position: fixed;\r\n  font-weight: bold !important;\r\n}\r\n\r\n.top_section {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  padding: 20px;\r\n}\r\n\r\n.logo {\r\n  font-size: 30px;\r\n  color: #0a6e2b;\r\n}\r\n\r\n.bars {\r\n  display: flex;\r\n  font-size: 25px;\r\n  margin-left: 50px;\r\n}\r\n\r\n.link {\r\n  display: flex;\r\n  color: #000000;\r\n  padding: 10px 15px;\r\n  gap: 15px;\r\n  transition: all 0.5s;\r\n}\r\n\r\n.link.bottom {\r\n  margin-bottom: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.link:hover {\r\n  background-color: #0a6e2b;\r\n  transition: all 0.5s;\r\n}\r\n\r\n.active {\r\n  background-color: #0a6e2b;\r\n}\r\n\r\n.icon,\r\n.link-text {\r\n  font-size: 20px;\r\n}\r\n\r\n/* div {\r\n  font-weight: bold;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
