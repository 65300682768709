import React, { useState } from "react";
import "./NewUserModal.css";

function NewUserModal({ setShowNewUserModal, addUser, errorMessage }) {
  const userInfo = { username: "", password: "", uuid: "-" };
  const [formValues, setFormValues] = useState(userInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addUser(formValues);
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="title">
          <h1>Add New User</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="body">
            <div className="wrap-input">
              <input
                className="input"
                type="text"
                name="username"
                placeholder="Username"
                value={formValues.username}
                onChange={handleChange}
                required
                autoFocus
              />
            </div>

            <div className="wrap-input">
              <input
                className="input"
                type="text"
                name="password"
                placeholder="User Pasword"
                value={formValues.password}
                onChange={handleChange}
                required
              />
            </div>
            <p>{errorMessage}</p>
          </div>
          <div className="footer">
            <button
              className="modalbutton"
              onClick={() => setShowNewUserModal(false)}
              id="cancel"
            >
              Cancel
            </button>
            <button className="modalbutton" type="submit">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewUserModal;
