import React, { useState } from "react";
import "./ConfirmationModal.css";

function ConfirmationModal(props) {
  const clearMsg = "Clear all logs";
  const deleteMsg = "Delete the log";
  const deleteUserMsg = "Are you sure to delete user: ";
  const resetUserMsg = "Are you sure to reset user: ";

  let user = "";

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setShowConfirmation(false);

    //LOG OPERATIONS
    if (props.confirmationMessage.msg === clearMsg) {
      props.clearAll();
    }

    //USER OPERATIONS
    if (props.confirmationMessage.msg === deleteUserMsg) {
      props.deleteUser(props.confirmationMessage.usr);
    }

    if (props.confirmationMessage.msg === resetUserMsg) {
      props.resetUser(props.confirmationMessage.usr);
    }
  };

  return (
    <div className="confModalBackground">
      <div className="confModalContainer">
        <form onSubmit={handleSubmit}>
          <div className="title">
            <h1>Confirmation</h1>
          </div>
          <div className="body">
            <p>
              {props.confirmationMessage.usr !== undefined
                ? props.confirmationMessage.msg + props.confirmationMessage.usr
                : props.confirmationMessage.msg}
            </p>
          </div>
          <div className="footer">
            <button
              className="modalbutton"
              onClick={() => props.setShowConfirmation(false)}
              id="cancel"
            >
              Cancel
            </button>
            <button className="modalbutton" type="submit">
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmationModal;
