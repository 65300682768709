import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Sidebar from "./components/Sidebar";
import Users from "./pages/Users";
import Logs from "./pages/Logs";
import React, { useState, useEffect } from "react";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize with the value from localStorage, or default to false if not found
    return localStorage.getItem("isLoggedIn") === "true";
  });

  const navigate = useNavigate();

  //This useEffect can be updated or deleted when it is requires to start with "/" path
  useEffect(() => {
    if (isLoggedIn) {
      // Navigate to the '/users' route when the user is logged in
      navigate("/users");
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn === false) {
    return <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />;
  }

  return (
    <>
      {/* MenuItems in the sidebar should be updated when the other sidebar items uncommented */}
      <Sidebar setIsLoggedIn={setIsLoggedIn}>
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/logs" element={<Logs />}></Route>
        </Routes>
      </Sidebar>
    </>
  );
}

export default App;
