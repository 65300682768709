// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #cac5c5;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.userOperation {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.tableDiv {
  padding: 20px 20px;
  margin-left: 200px;
  font-weight: bold;
}

.unregisteredUser {
  background-color: #daffda !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["table {\r\n  border-collapse: collapse;\r\n  width: 100%;\r\n}\r\n\r\ntd,\r\nth {\r\n  border: 1px solid #cac5c5;\r\n  text-align: left;\r\n  padding: 8px;\r\n}\r\n\r\ntr:nth-child(even) {\r\n  background-color: #dddddd;\r\n}\r\n\r\n.userOperation {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.tableDiv {\r\n  padding: 20px 20px;\r\n  margin-left: 200px;\r\n  font-weight: bold;\r\n}\r\n\r\n.unregisteredUser {\r\n  background-color: #daffda !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
