import React, { useEffect, useState } from "react";
import "./Users.css";
import { IoIosAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { GrPowerReset } from "react-icons/gr";
import { FaDownload } from "react-icons/fa";
import NewUserModal from "../components/NewUserModal";
import ConfirmationModal from "../components/ConfirmationModal";

function Users() {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState({
    msg: "",
    usr: "",
  });

  const deleteUserMsg = "Are you sure to delete user: ";
  const resetUserMsg = "Are you sure to reset user: ";

  useEffect(() => {
    getUsers();
  }, []);

  const path = "http://host.gesteknik.com:3000/";

  const getUsers = () => {
    fetch(path + "users")
      .then((res) => res.json())
      .then((data) => setUsers(data))
      .catch((err) => {
        console.log("Fetch error: ", err.message);
      });
  };

  const deleteUser = (username) => {
    fetch(path + "delete", {
      method: "POST",
      body: JSON.stringify({
        name: username,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .catch((error) => {
        console.error(error);
      })
      .then((response) => {
        if (response.status === 200) {
          getUsers();
          setShowNewUserModal(false);
        } else {
          setErrorMessage("An error occured");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const resetUser = (username) => {
    fetch(path + "reset", {
      method: "POST",
      body: JSON.stringify({
        name: username,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          getUsers();
          setShowNewUserModal(false);
        } else {
          setErrorMessage("An error occured");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addUser = ({ username, password, uuid }) => {
    fetch(path + "users", {
      method: "POST",
      body: JSON.stringify({
        name: username,
        password: password,
        uuid: uuid,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.status === 201) {
          getUsers();
          setShowNewUserModal(false);
        } else if (response.status === 400) {
          setErrorMessage("The user already exists.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const downloadUsers = () => {
    if (users.length != 0) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(users)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "userlist.json";

      link.click();
    }
  };

  return (
    <div>
      {showNewUserModal && (
        <NewUserModal
          setShowNewUserModal={setShowNewUserModal}
          addUser={addUser}
          errorMessage={errorMessage}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          confirmationMessage={confirmationMessage}
          setShowConfirmation={setShowConfirmation}
          deleteUser={deleteUser}
          resetUser={resetUser}
        />
      )}
      <div className="tableDiv">
        <table className="user_table">
          <thead>
            <tr>
              <th style={{ width: "4%" }}></th>
              <th style={{ display: "flex", justifyContent: "space-between" }}>
                User
                <div style={{ display: "flex", gap: "20px" }}>
                  <div className="userOperation" onClick={downloadUsers}>
                    <FaDownload className="icon" /> Download
                  </div>
                  <div
                    className="userOperation"
                    onClick={() => {
                      setShowNewUserModal(true);
                    }}
                  >
                    <IoIosAddCircle
                      className="icon"
                      style={{ color: "green" }}
                    />
                    Add User
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((item, index) => (
              <tr
                key={index}
                className={item.uuid === "-" ? "unregisteredUser" : ""}
              >
                <td>{index + 1}</td>
                <td
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {item.name}
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div
                      className="userOperation"
                      onClick={() => {
                        setShowConfirmation(true);
                        setConfirmationMessage({
                          msg: resetUserMsg,
                          usr: item.name,
                        });
                      }}
                    >
                      <GrPowerReset className="icon" /> Reset User
                    </div>
                    <div
                      className="userOperation"
                      onClick={() => {
                        setShowConfirmation(true);
                        setConfirmationMessage({
                          msg: deleteUserMsg,
                          usr: item.name,
                        });
                      }}
                    >
                      <IoMdRemoveCircle
                        className="icon"
                        style={{ color: "red" }}
                      />{" "}
                      Delete User
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Users;
