import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./Dashboard.css";

export default function Dashboard(props) {

  const navigate = useNavigate();

  return (
    <div className="dashboardDiv">
      <h1>Dashboard</h1>
    </div>
  );
}
