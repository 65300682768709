import "./Login.css";
import React, { useState } from "react";
import user from "../user.json";

function Login(props) {
  const userInfo = { username: "", password: "" };
  const [formValues, setFormValues] = useState(userInfo);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const foundUser = user.userInfo.findIndex(
      (user) => user.username === formValues.username
    );

    if (foundUser !== -1) {
      if (user.userInfo[foundUser].password === formValues.password) {
        props.setIsLoggedIn(true);
        localStorage.setItem("isLoggedIn", "true");
      }
    }

    setFormErrors(validate(formValues));
  };

  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = "Username is required!";
    } else if (!values.password) {
      errors.password = "Password is required!";
    } else {
      errors.wrongInfo = "Wrong username or password entered";
    }

    return errors;
  };

  return (
    <div>
      <div className="container-login">
        <div className="wrap-login">
          <form className="login-form" onSubmit={handleSubmit}>
            <span className="login-form-brand">S-Designer Users</span>
            <span className="login-form-title">
              <img src="/logo.png" alt="logo" className="login-form-logo"></img>
            </span>
            <span className="login-form-title">Login</span>
            <div className="container-input">
              <div className="wrap-input">
                <input
                  className="input"
                  type="username"
                  name="username"
                  placeholder="Username"
                  value={formValues.username}
                  onChange={handleChange}
                  autoFocus
                />
              </div>
              <p>{formErrors.username}</p>
            </div>

            <div className="container-input">
              <div className="wrap-input">
                <input
                  className="input"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formValues.password}
                  onChange={handleChange}
                />
              </div>
              <p>{formErrors.password}</p>
            </div>

            <p>{formErrors.wrongInfo}</p>

            <div className="container-login-form-btn">
              <button type="submit" className="login-form-btn">
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
