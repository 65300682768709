import React, { useState } from "react";
import {
  FaBars,
  FaDatabase,
  FaSignOutAlt,
  FaTh,
  FaUsers,
} from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

function Sidebar({ children, setIsLoggedIn }) {
  //MenuItems will be updated when the other sidebar items used
  const menuItem = [
    // {
    //   path: "/",
    //   name: "Dashboard",
    //   icon: <FaTh />,
    // },
    {
      path: "/users",
      name: "Users",
      icon: <FaUsers />,
    },
    // {
    //   path: "/logs",
    //   name: "Logs",
    //   icon: <FaDatabase />,
    // },
  ];

  const navigate = useNavigate();

  const logout = { name: "Logout", icon: <FaSignOutAlt /> };

  const handleClick = () => {
    navigate("/");
    setIsLoggedIn(false);
    localStorage.setItem("isLoggedIn", "false");
  };

  return (
    <div className="container">
      <div className="sidebar">
        <div>
          <div className="top_section">
            <h1 className="logo">S-Designer</h1>
            <h1 className="logo">Users</h1>
          </div>

          {menuItem.map((item, index) => (
            <NavLink to={item.path} key={index} className="link">
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
        </div>

        <div className="link bottom" onClick={handleClick}>
          <div className="icon">{logout.icon}</div>
          <div className="link_text">{logout.name}</div>
        </div>
      </div>
      <div className="main">{children}</div>
    </div>
  );
}

export default Sidebar;
